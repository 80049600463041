<template>
  <div :class="classObject">
    <p class="p-coffeeDetail__rateButtons__title">
      {{ title }}
    </p>
    <div class="p-coffeeDetail__rateButtons__inner">
      <button
        type="button"
        class="c-button c-button--fluid c-button--quaternary p-coffeeDetail__rateButton p-coffeeDetail__rateButton--bad"
        :class="{ 'u-pe-none': disabled }"
        @click="onSelectedBad"
        :v-ripple="false">
        <span class="p-coffeeDetail__rateButton__face">
          <span class="p-coffeeDetail__rateButton__effect" />
          <Icon name="faceBad" color="grey01" />
        </span>
        う〜ん…
      </button>
    </div>
    <div class="p-coffeeDetail__rateButtons__inner">
      <button
        type="button"
        class="c-button c-button--fluid c-button--quaternary p-coffeeDetail__rateButton p-coffeeDetail__rateButton--good"
        :class="{ 'u-pe-none': disabled }"
        @click="onSelectedGood">
        <span class="p-coffeeDetail__rateButton__face">
          <span class="p-coffeeDetail__rateButton__effect" />
          <Icon name="faceGood" color="accent02" />
        </span>
        好き！
      </button>
    </div>
  </div>
  <!-- /.p-coffeeDetail__rateButtons -->
</template>

<script>
export default {
  props: {
    /**
     * @type {{ like: number; hate: number; }}
     */
    impressionCount: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    blockName: 'p-coffeeDetail__rateButtons',

    isSelectedGood: false,
    isSelectedBad: false,

    title: 'あなたの感想を登録してマイルをゲットしよう'
  }),

  computed: {
    disabled() {
      return this.$_loading_state;
    },

    isSelectedGoodClass() {
      return this.isSelectedGood ? this.blockName + '--selectedGood' : '';
    },

    isSelectedBadClass() {
      return this.isSelectedBad ? this.blockName + '--selectedBad' : '';
    },

    classObject() {
      return [
        this.blockName,
        this.hasImpressionsClass,
        this.isSelectedGoodClass,
        this.isSelectedBadClass
      ];
    }
  },

  methods: {
    onSelectedGood() {
      this.isSelectedGood = true;
      this.isSelectedBad = false;
      this.title = `${
        this.impressionCount.like + 1
      }回目の「好き！」をしました！`;

      this.$emit('click', true);
    },

    onSelectedBad() {
      this.isSelectedBad = true;
      this.isSelectedGood = false;
      this.title = `${
        this.impressionCount.hate + 1
      }回目の「う〜ん･･･」をしました`;

      this.$emit('click', false);
    }
  }
};
</script>
